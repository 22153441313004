import { ReactNode, useState, useLayoutEffect } from 'react';

import Analytics, { AnalyticsInstance } from 'analytics';
import { AnalyticsProvider as Provider, useAnalytics } from 'use-analytics';

import fullStoryPlugin from './analytics-plugin-fullstory';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';

function AnalyticsProvider({ children }: { children: ReactNode }) {
  const [analytics, setAnalytics] = useState<AnalyticsInstance | null>(null);
  const allFeatures= useFeatureFlagState();
  const ENV = allFeatures.features?.ENV;

  const plugins =  ENV === 'production' ? [
    fullStoryPlugin({
      org: 'o-19GD0V-na1',
      namespace: 'FullStory',
    }) as Record<string, unknown>,
  ] : []

  useLayoutEffect(() => {
    try {
      const _analytics = Analytics({
        app: 'SkyPassport',
        plugins,
      });

      setAnalytics(_analytics);
    } catch (e) {
      console.error('Analytics failed to load');
    }
  }, []);

  if (!analytics) {
    return <>{children}</>;
  }

  return <Provider instance={analytics}>{children}</Provider>;
}
export { AnalyticsProvider, useAnalytics };
