import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosInterceptor } from 'libs/axios';
import { AnalyticsProvider } from 'libs/analytics';
import { I18nextProvider } from 'libs/i18next';
import AppRoutes from './Routes';
import SetMarket from 'services/MarketSetter';
import * as Sentry from '@sentry/react';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {appStorage} from 'utils/storage';
import queryClient from 'libs/react-query';

function App() {
  appStorage.removeItem('connectPlusSessionId');
  const allFeatures= useFeatureFlagState();
  const ENV = allFeatures.features?.ENV;

  const getTracesSampleRate = () => {
    if (ENV === 'production') {
        return 1
    } else if (ENV === 'staging') {
        return 0.25
    }
    return 0.1
  }
  const sentryClient = Sentry.getCurrentHub().getClient();

  if(!sentryClient){
    Sentry.init({
      dsn: 'https://c3c194627c6966c3332875847e7dd3bd@o375285.ingest.sentry.io/4505982826643456',
      integrations: [],
      environment: ENV,
      tracesSampleRate: getTracesSampleRate(),
      tracePropagationTargets: [window.location.origin],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,

      // Callback to modify event before it is sent
      beforeSend(event, hint) {
          console.log('=== SENTRY ===', event, hint);
          return event;
      },
    });
  }

return(
    <BrowserRouter>
      {/* This is for Kube  */}
      <Routes>
        <Route path="/live" element={<h1>Live</h1>} />
        <Route path="*" element={<></>} />
      </Routes>
      <SetMarket>
        <AnalyticsProvider>
          <I18nextProvider>
            <QueryClientProvider client={queryClient}>
              <AxiosInterceptor>
                <AppRoutes />
                <ReactQueryDevtools initialIsOpen={false} />
                <ToastContainer />
              </AxiosInterceptor>
            </QueryClientProvider>
          </I18nextProvider>
        </AnalyticsProvider>
      </SetMarket>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
